@import url('https://use.typekit.net/zsn5oys.css');

@font-face {
  font-family: 'ProximaNovaBold';
  src: url('proxima-nova/proxima-nova-bold.woff2') format('woff2'),
    url('proxima-nova/proxima-nova-bold.woff') format('woff'),
    url('proxima-nova/proxima-nova-bold.ttf') format('truetype'),
    url('proxima-nova/proxima-nova-bold.otf') format('otf');
}

@font-face {
  font-family: 'ProximaNovaSemiBold';
  src: url('proxima-nova/proxima-nova-semi-bold.woff2') format('woff2'),
    url('proxima-nova/proxima-nova-semi-bold.woff') format('woff'),
    url('proxima-nova/proxima-nova-semi-bold.ttf') format('truetype'),
    url('proxima-nova/proxima-nova-semi-bold.otf') format('otf');
}
